<template>
	<no-auth>
		<div style="background: #fff;padding: 15px;" v-loading='containloading'>
			<div class="filter-container">
				<!-- 代理等级 -->
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" @keyup.enter.native="goodsSearch" placeholder="商品名称" style="width: 200px;;margin-right: 10px;"
					 clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">状态: </label>
					<el-select v-model="searchState" style="width: 150px;margin-right: 10px;" clearable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in searchStateList" :key="item.id" :label="item.value" :value="item.id">
						</el-option>
					</el-select>
				</div>

				<div class="filter-item">
					<el-button type="primary" style="margin-left:25px;width:90px;" @click="goodsSearch">查询</el-button>
					<button-permissions :datas="'addNogoods'">
						<el-button type="primary" style="margin-left:25px;width:120px;" @click="handlePrizePro()">添加商品</el-button>
					</button-permissions>
				</div>

			</div>

			<div style="color: #999;font-size: 13px;margin-bottom: 20px;">提审额度：今日还可提交{{RemainSubmitCount}}次审核</div>


			<div class="table-container">
				<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable">
					<el-table-column label="商品" width="450">
						<template slot-scope="scope">
							<div class="product-info" style="align-items:flex-start">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div style="min-height: auto;">
									<div style="display:flex;justify-content:space-between;align-items:flex-start;">
										<div style="width:300px;" class="overflowTwo">
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
										</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Price" label="价格">
						<template slot-scope="scope">
							<div v-if='scope.row.PriceType == 1'>{{scope.row.Price}}</div>
							<div v-if='scope.row.PriceType == 2'>{{scope.row.Price}} ~ {{scope.row.Price2}}</div>
							<div v-if='scope.row.PriceType == 3'>
								<div>现价:{{scope.row.Price2}}</div>
								<div>市场价:{{scope.row.Price}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Url" label="链接">

					</el-table-column>
					<el-table-column prop="AuditStatus" label="状态">
						<template slot-scope="scope">
							<div v-if='scope.row.AuditStatus == 0'>未审核</div>
							<div v-if='scope.row.AuditStatus == 1'>审核中</div>
							<div v-if='scope.row.AuditStatus == 2'>审核通过</div>
							<div v-if='scope.row.AuditStatus == 3'>审核失败</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="250">
						<template slot-scope="scope">
							<buttonPermissions :datas="'SubmitcheckBtn'" v-if='scope.row.AuditStatus == 0 || scope.row.AuditStatus == 3'>
								<el-button type="text" @click="SubmitCheck(scope.row)" style='margin-right: 10px;'>提交审核</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'EditselctgoodsBtn'" v-if='scope.row.AuditStatus == 0 || scope.row.AuditStatus == 3'>
								<el-button type="text" @click="EditPro(scope.row)" style='margin-right: 10px;'>编辑</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'DeletecheckBtn'" v-if='scope.row.AuditStatus == 1'>
								<el-button type="text" @click="Deletecheck(scope.row)" style='margin-right: 10px;'>撤销审核</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'deleteCheckgoodsBtn'" v-if='scope.row.AuditStatus == 0 || scope.row.AuditStatus == 3'>
								<span style="color:#f00;margin-left:10px;cursor: pointer;" @click="deleteChose(scope.row)">删除</span>
							</buttonPermissions>
						</template>
					</el-table-column>
				</el-table>
				<div style="text-align: right;margin-top: 10px;">
					<el-pagination v-if="Total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
					 :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="Total">
					</el-pagination>
				</div>

			</div>
			<!-- 选择商品-->
			<el-dialog title="选择商品" :visible.sync="selectProShow" v-if="selectProShow" width="1100px" class="dialog data-dialog">
				<select-produce :proCheckedId='proCheckedId' @getSelection='getSelectPros' :allReadyproductInfo='allReadyproductInfo'></select-produce>
			</el-dialog>

			<!-- //添加商品表单 -->
			<!-- :rules="rules" -->
			<el-dialog :title="goodsTitle" :visible.sync="addProVisible" width="800px" class="dialog data-dialog" v-loading='productloading'
			 @close="Closeproduct">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<div v-if="editId" style="padding: 10px;background:#FDF6EC;font-size: 14px;color:#FDA23C;margin-bottom: 10px;">
						未审核或审核不通过的商品，编辑商品时，请先保存，保存成功后，列表点击[提交审核]
					</div>
					<div class="goodsInfo">
						<div style="color: #000;">商品封面：</div>
						<div style="font-size: 13px;margin: 10px 0;">图片最大尺寸：300像素x300像素</div>
						<div class="operateBtn" style="display: flex;align-items: center;">
							<!-- +'@!cut64' -->
							<img :src="imgUrl+ ruleForm.ImgUrl" alt="" style="width:100px;height:100px;margin-right: 30px;" v-if="ruleForm.ImgUrl">
							<!-- <img :src="imgUrl+ selectProductInfo.ImgUrl" alt="" style="width:100px;height:100px;margin-right: 30px;" v-if="selectProductInfo.ImgUrl"> -->
							<el-upload :action="imgApi" list-type="picture-card" :on-success="handleAvatarSuccessfu" accept="image/png,image/jpeg,image/jpg,image/gif"
							 :show-file-list="false" :before-upload="beforeAvatarUpload">
								<div>
									<el-button type="text">上传图片</el-button>
								</div>
							</el-upload>
							<el-button type="text" @click="selectPrizePro(selectProductInfo)" style="margin-left: 30px;">选择商品</el-button>
							<!-- <el-button type="text" @click="handlePrizePro()" style="margin-left: 30px;">选择商品</el-button> -->

						</div>
					</div>
					<el-form-item prop="productName" label-width="0px">
						<label>商品名称：</label>
						<el-input v-model="ruleForm.productName" @input='limitinput(ruleForm.productName)' placeholder="请输入商品名称" size="small"
						 style="width: 400px;"></el-input>
					</el-form-item>
					<div class="priceStyle" style="display: flex;margin-top: 20px;">
						<label style="color:#606266;font-size: 14px;margin-right: 10px;padding-top: 10px;">价格形式：</label>
						<div class="pricelist">
							<div class="price_item">
								<el-radio v-model="ruleForm.selectPrice" :label="1">一口价</el-radio>
								<!-- :rules="ruleForm.selectPrice == 1?rules.fixedPrice:rules.nocheck" -->
								<el-form-item prop="fixedPrice" label-width="0px" style="margin-bottom: 0px;">
									<label style="margin-right:10px;">价格</label>
									<el-input v-model="ruleForm.fixedPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==1?false:true"
									 @input='OnlyMoney(ruleForm.fixedPrice,1)'></el-input> 元
								</el-form-item>
							</div>
							<div class="price_item">
								<el-radio v-model="ruleForm.selectPrice" :label="2">价格区间</el-radio>
								<!-- :rules="ruleForm.selectPrice == 2?rules.minPrice:rules.nocheck" -->
								<el-form-item prop="minPrice" label-width="0px" style="margin-bottom: 0px;">
									<label style="margin-right:10px;">价格</label>
									<el-input v-model="ruleForm.minPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==2?false:true"
									 @input='OnlyMoney(ruleForm.minPrice,2)'></el-input> 元 <span style="margin: 0 10px;">-</span>
								</el-form-item>
								<!-- :rules="ruleForm.selectPrice == 2?rules.maxPrice:rules.nocheck" -->
								<el-form-item prop="maxPrice" label-width="0px" style="margin-bottom: 0px;">
									<el-input v-model="ruleForm.maxPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==2?false:true"
									 @input='OnlyMoney(ruleForm.maxPrice,3)'></el-input> 元
								</el-form-item>
							</div>
							<div class="price_item">
								<el-radio v-model="ruleForm.selectPrice" :label="3">显示折扣价</el-radio>
								<!-- :rules="ruleForm.selectPrice == 3?rules.marketPrice:rules.nocheck" -->
								<el-form-item prop="marketPrice" label-width="0px" style="margin-bottom: 0px;">
									<label style="margin-right:10px;">市场价</label>
									<el-input v-model="ruleForm.marketPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==3?false:true"
									 @input='OnlyMoney(ruleForm.marketPrice,4)'></el-input> 元
								</el-form-item>
								<!-- :rules="ruleForm.selectPrice == 3?rules.nowPrice:rules.nocheck" -->
								<el-form-item prop="nowPrice" label-width="0px" style="margin-bottom: 0px;">
									<label style="margin:0 15px;">现价</label>
									<el-input v-model="ruleForm.nowPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==3?false:true"
									 @input='OnlyMoney(ruleForm.nowPrice,5)'></el-input> 元
								</el-form-item>
							</div>

						</div>

					</div>

					<el-form-item prop="productLink" label-width="0px">
						<label>商品链接：</label>
						<el-input v-model="ruleForm.productLink" style="width:350px;"></el-input>
					</el-form-item>
				</el-form>

				<div class="footerBtn" style="text-align: center;">
					<el-button style="width: 120px;margin-right: 10px;" @click="Closeproduct">取消</el-button>
					<el-button type="primary" style="width: 120px;margin-right: 10px;" :loading="editLoading" @click="SaveInfo('ruleForm',0)">{{editId?'保存':'保存并提审'}}</el-button>
				</div>
			</el-dialog>
		</div>
	</no-auth>
</template>

<script>
	import {
		unstockedIndexlist,
		unstockedAddlist,
		unstockedelete,
		unstockedcancel,
		unstockedsubmit,
		unstockededitInfo
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import selectProduce from './Selectlivegoods'
	import buttonPermissions from '@/components/buttonPermissions';
	
	import noAuth from './components/noAuth.vue';
	
	export default {
		components: {
			selectProduce,
			buttonPermissions,
			noAuth
		},
		data() {
			return {
				goodsTitle: '',
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				groupData: [],
				loading: false,
				checkNum: 999,
				searchKey: '',
				searchState: null,
				searchStateList: [{
					value: '未审核',
					id: 0
				}, {
					value: '审核中',
					id: 1
				}, {
					value: '审核失败',
					id: 3
				}],
				selectProShow: false,
				proCheckedId: 0,
				addProVisible: false,
				ruleForm: {
					productName: '',
					BackgroupImg: '',
					selectPrice: 1,
					fixedPrice: '',
					minPrice: '',
					maxPrice: '',
					marketPrice: '',
					nowPrice: '',
					productLink: '',
					ImgUrl: ''
				},
				IslimitLength: false,
				savaloading: false,
				saveAndloading: false,
				RemainSubmitCount: 0,
				selectProductInfo: {},
				containloading: false,
				productloading: false,
				editId: null,
				allReadyproductInfo: null,
				editLoading: false
			}
		},
		created() {
			this.getList()
		},
		methods: {
			//取消之后
			Closeproduct() {
				this.selectProductInfo = {}
				this.addProVisible = false
			},
			//保存信息
			SaveInfo(formName, index) {
				if (this.IslimitLength) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '商品名称必须为3-14个汉字，1个汉字等于2个英文字母或特殊字符'
					})
					return
				}
				if (this.ruleForm.selectPrice == 1) {
					if (!this.ruleForm.fixedPrice && this.ruleForm.fixedPrice !== 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入价格'
						})
						return
					}
					if (Number(this.ruleForm.fixedPrice) > 9999999 || Number(this.ruleForm.fixedPrice) == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '价格请设置在0.01~9999999之间'
						})
						return
					}
				}
				if (this.ruleForm.selectPrice == 2) {
					if (!this.ruleForm.minPrice && this.ruleForm.minPrice !== 0 || (!this.ruleForm.maxPrice && this.ruleForm.maxPrice !==
							0)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入价格'
						})
						return
					}
					if (Number(this.ruleForm.minPrice) >= Number(this.ruleForm.maxPrice)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请设置正确的价格区间'
						})
						return
					}
					if (Number(this.ruleForm.minPrice) > 9999999 || Number(this.ruleForm.minPrice) == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '价格请设置在0.01~9999999之间'
						})
						return
					}
					if (Number(this.ruleForm.maxPrice) > 9999999 || Number(this.ruleForm.maxPrice) == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '价格请设置在0.01~9999999之间'
						})
						return
					}
				}
				if (this.ruleForm.selectPrice == 3) {
					if (!this.ruleForm.marketPrice && this.ruleForm.marketPrice !== 0 || (!this.ruleForm.nowPrice && this.ruleForm.nowPrice !==
							0)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入价格'
						})
						return
					}
					if (Number(this.ruleForm.marketPrice) > 10000000 || Number(this.ruleForm.nowPrice) > 10000000) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入价格'
						})
						return
					}
					if (Number(this.ruleForm.marketPrice) > 9999999 || Number(this.ruleForm.marketPrice) == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '价格请设置在0.01~9999999之间'
						})
						return
					}
					if (Number(this.ruleForm.nowPrice) > 9999999 || Number(this.ruleForm.nowPrice) == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '价格请设置在0.01~9999999之间'
						})
						return
					}
					if (Number(this.ruleForm.marketPrice) <= Number(this.ruleForm.nowPrice)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '现价需低于市场价'
						})
						return
					}

				}
				if (this.ruleForm.productLink == '') {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入商品链接'
					})
					return
				}
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						//仅保存
						if (index == 0) {

							if (this.editId) {
								try {
									this.editLoading = true
									let data = {
										ProductId: this.selectProductInfo.ProductId,
										ProductName: this.ruleForm.productName,
										PriceType: this.ruleForm.selectPrice,
										Url: this.ruleForm.productLink
									}
									if (this.ruleForm.ImgUrl.indexOf("@!cut300_300") == -1) {
										data.ImgUrl = this.ruleForm.ImgUrl + '@!cut300_300'
									} else {
										data.ImgUrl = this.ruleForm.ImgUrl
									}

									if (data.PriceType == 1) {
										data.Price = this.ruleForm.fixedPrice
									}
									if (data.PriceType == 2) {
										data.Price = this.ruleForm.minPrice
										data.Price2 = this.ruleForm.maxPrice
									}
									if (data.PriceType == 3) {
										data.Price = this.ruleForm.marketPrice
										data.Price2 = this.ruleForm.nowPrice
									}

									let result;
									if (this.editId) {
										data.Id = this.editId
										result = await unstockededitInfo(data)
									} else {
										result = await unstockedAddlist(data)
									}
									if (result.IsSuccess) {
										this.addProVisible = false
										this.$message({
											showClose: true,
											type: 'success',
											message: '操作成功!'
										});
										this.getList()
										setTimeout(() => {
											this.editLoading = false
										}, 2000)
									}
								} catch (err) {
									this.editLoading = false
								} finally {

								}
							} else {
								let message = '温馨提示：审核大约需要1-7天'
								this.$confirm(message, {
									confirmButtonText: '保存',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(async () => {
									try {
										this.productloading = true
										let data = {
											ProductId: this.selectProductInfo.ProductId,
											ProductName: this.ruleForm.productName,
											PriceType: this.ruleForm.selectPrice,
											Url: this.ruleForm.productLink
										}
										if (this.ruleForm.ImgUrl.indexOf("@!cut300_300") == -1) {
											data.ImgUrl = this.ruleForm.ImgUrl + '@!cut300_300'
										} else {
											data.ImgUrl = this.ruleForm.ImgUrl
										}

										if (data.PriceType == 1) {
											data.Price = this.ruleForm.fixedPrice
										}
										if (data.PriceType == 2) {
											data.Price = this.ruleForm.minPrice
											data.Price2 = this.ruleForm.maxPrice
										}
										if (data.PriceType == 3) {
											data.Price = this.ruleForm.marketPrice
											data.Price2 = this.ruleForm.nowPrice
										}

										let result;
										if (this.editId) {
											data.Id = this.editId
											result = await unstockededitInfo(data)
										} else {
											result = await unstockedAddlist(data)
										}
										if (result.IsSuccess) {
											this.addProVisible = false
											this.$message({
												showClose: true,
												type: 'success',
												message: '操作成功!'
											});
											this.getList()
											setTimeout(() => {
												this.productloading = false
											}, 2000)
										}
									} catch (err) {
										this.productloading = false
									} finally {

									}


								}).catch(() => {
									this.$message({
										showClose: true,
										type: 'info',
										message: '已取消操作'
									});
								}).finally(() => {
									// this.currentPage = 1;
									// setTimeout(() => {
									// 	this.goodsSearch();
									// }, 500)

								})
							}


						}




					} else {}
				});

			},
			OnlyMoney(obj, index) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				if (index == 1) {
					this.ruleForm.fixedPrice = obj
				}
				if (index == 2) {
					this.ruleForm.minPrice = obj
				}
				if (index == 3) {
					this.ruleForm.maxPrice = obj
				}
			},
			getStringLen(Str) {
				var i, len, code;
				if (Str == null || Str == "") return 0;
				len = Str.length;
				for (i = 0; i < Str.length; i++) {
					code = Str.charCodeAt(i);
					if (code > 255) {
						len++;
					}
				}
				return len;
			},
			checkLen(value, minlength, maxlength) {
				var len = this.getStringLen(value);
				//提示当前输入的个数
				console.log(len, '当前输入的字符长度')
				if (len > maxlength || len < minlength) {
					this.IslimitLength = true
				} else {
					this.IslimitLength = false
				}

			},
			//限制输入
			limitinput(obj) {
				this.checkLen(obj, 6, 28)
			},
			// 图片长传-之前
			beforeAvatarUpload(file) {
				let self = this;
				let type_arr = ["image/jpeg", "image/png"];
				let type = file.type;
				if (!type_arr.includes(type)) {
					this.$message.error("图片格式不正确,只支持jpg和png类型图片");
					return false;
				}

				const is_size = new Promise((resolve, reject) => {
					let width = 300;
					let height = 300;
					let img = new Image();
					img.src = window.URL.createObjectURL(file);
					img.onload = () => {
				
						let valid = img.width <= width && img.height <= height;
						if (!valid) {
							self.$message.error("商品封面图尺寸最大300像素*300像素");
							reject();
						} else {
							resolve(file);
						}
					};
				});
				return is_size;
				// console.log(is_size,'上传之前')


			},
			//上传图片
			handleAvatarSuccessfu(res, file) {
				this.ruleForm.ImgUrl = file.response[0]
				// this.selectProductInfo.ImgUrl = file.response[0]
			},
			//提交审核
			SubmitCheck(record) {
				let message = '温馨提示：审核大约需要1-7天'
				this.$confirm(message, {
					confirmButtonText: '提交审核',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try {
						this.containloading = true
						let data = {
							Id: record.Id
						}
						let result = await unstockedsubmit(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功!'
							});
							this.getList()
							setTimeout(() => {
								this.containloading = false
							}, 2000)
						}
					} catch (err) {
						this.containloading = false
					} finally {

					}


				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					this.currentPage = 1;
					setTimeout(() => {
						this.goodsSearch();
					}, 500)

				})
			},
			//撤销审核
			Deletecheck(record) {
				this.$confirm('确认撤销审核吗？撤销后,本次提审不生效，提审额度不返还', '提示', {
					confirmButtonText: '确认撤销审核',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try {
						let result = await unstockedcancel({
							Id: record.Id
						})
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '撤销成功!'
							});
							this.getList()
						}
					} catch (err) {

					} finally {

					}


				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					setTimeout(() => {
						this.goodsSearch();
					}, 500)

				})
			},
			//删除
			deleteChose(record) {
				this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try {
						let result = await unstockedelete({
							Id: record.Id
						})
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getList()
						}
					} catch (err) {

					} finally {

					}


				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					this.currentPage = 1;
					setTimeout(() => {
						this.goodsSearch();
					}, 500)

				})

			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			// 获取商品列表
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						AuditStatus: this.searchState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await unstockedIndexlist(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];

					this.RemainSubmitCount = result.Result.RemainSubmitCount

				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false
					}, 500)

				}

			},
			//编辑商品信息
			EditPro(record) {
				this.addProVisible = true
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
				this.goodsTitle = '编辑商品'
				this.editId = record.Id
				this.ruleForm.ImgUrl = record.ImgUrl
				this.selectProductInfo = record
				this.proCheckedId = record.ProductId
				this.ruleForm.productName = record.ProductName
				this.ruleForm.selectPrice = record.PriceType
				if (record.PriceType == 1) {
					this.ruleForm.fixedPrice = record.Price
				}
				if (record.PriceType == 2) {
					this.ruleForm.minPrice = record.Price
					this.ruleForm.maxPrice = record.Price2
				}
				if (record.PriceType == 3) {
					this.ruleForm.marketPrice = record.Price
					this.ruleForm.nowPrice = record.Price2
				}
				this.ruleForm.productLink = record.Url

			},
			//只是添加商品的时候
			handlePrizePro() {
				//已经选择了商品
				this.proCheckedId = 0
				this.selectProShow = true
				this.allReadyproductInfo = null
			},
			//切换商品
			selectPrizePro(record) {
				this.allReadyproductInfo = record
				this.proCheckedId = record.ProductId
				this.selectProShow = true
			},
			//添加商品
			// handlePrizePro(record) {
			// 	//已经选择了商品
			// 	if (record.ProductId) {
			// 		this.allReadyproductInfo = record
			// 		// this.selection.ProductId = record.ProductId
			// 	} else {
			// 		this.proCheckedId = 0
			// 		this.allReadyproductInfo = null

			// 	}
			// 	this.selectProShow = true

			// },
			goodsSearch() {
				this.currentPage = 1;
				this.getList();
			},
			getSelectPros(selection, isChecked) {
				console.log(selection,'被选中的商品')
				//确定选中商品
				if (selection.ProductId) {
					this.selectProductInfo = selection
					this.ruleForm.ImgUrl = this.selectProductInfo.ImgUrl
					this.proCheckedId = this.selectProductInfo.ProductId
					this.ruleForm.productLink = 'pages/detail/detail?id=' + this.selectProductInfo.ProductId
					this.ruleForm.productName = this.selectProductInfo.Name
					this.checkLen(this.ruleForm.productName, 6, 28)
					this.ruleForm.selectPrice = 1
					this.ruleForm.fixedPrice = ''
					this.ruleForm.minPrice = ''
					this.ruleForm.maxPrice = ''
					this.ruleForm.marketPrice = ''
					this.ruleForm.nowPrice = ''
					// if(selection.PriceType){
					// 	this.ruleForm.productName = this.selectProductInfo.ProductName
					// 	this.ruleForm.selectPrice = selection.PriceType
					// 	if (selection.PriceType == 1) {
					// 		this.ruleForm.fixedPrice = selection.Price
					// 	}
					// 	if (selection.PriceType == 2) {
					// 		this.ruleForm.minPrice = selection.Price
					// 		this.ruleForm.maxPrice = selection.Price2
					// 	}
					// 	if (selection.PriceType == 3) {
					// 		this.ruleForm.marketPrice = selection.Price
					// 		this.ruleForm.nowPrice = selection.Price2
					// 	}
					// }
					// else{
					// 	this.ruleForm.productName = this.selectProductInfo.Name
					// 	this.ruleForm.selectPrice = 1
					// 	this.ruleForm.fixedPrice = ''
					// 	this.ruleForm.minPrice = ''
					// 	this.ruleForm.maxPrice = ''
					// 	this.ruleForm.marketPrice = ''
					// 	this.ruleForm.nowPrice = ''
					// }
				}
				this.editId = null
				this.goodsTitle = '添加商品'
				this.selectProShow = false
				this.addProVisible = true
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})


			},
		}
	}
</script>

<style lang="less" scoped>
	.price_item {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		label {
			width: 100px;
			text-align: left;
			// border: 1px solid  red;
		}
	}

	.overflowTwo {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	::v-deep .el-upload--picture-card {
		border: none;
		background: none;
		width: 100px !important;
		height: 35px !important;
		line-height: 0px !important;
	}
</style>
